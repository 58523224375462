import React, { useEffect, useState } from "react";
import TrackingHeader from "./TrackingHeader";
import CardPoolTracking from "./CardPoolTracking";
import SimpleBar from "simplebar-react";
import { TeamMember } from "../../../types/interfaces/IDayInfo";
import EmployeeCard from "../../../pages/scheduler/month/widgets/drawer/schedule-drawer/EmployeeCard";

interface Props {
  specificDayEmployees: TeamMember[];
}
const TrackingFilter: React.FC<Props> = ({ specificDayEmployees }) => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [empsByDesignation, setEmpsByDesignation] = useState<TeamMember[]>();

  useEffect(() => {
    if (selectedFilter === "All") {
      setEmpsByDesignation(specificDayEmployees);
      return;
    }
    const withDesignation = specificDayEmployees?.filter((emp: TeamMember) => {
      if (emp?.role === selectedFilter) return emp;
    });
    setEmpsByDesignation(withDesignation);
  }, [specificDayEmployees, selectedFilter]);

  return (
    <>
      <div className="mb-4">
        <TrackingHeader
          title="Pool Tracking"
          setSelectedFilter={setSelectedFilter}
        />
      </div>

      <div
        className="employCrd flex flex-col gap-1 overflow-y-scroll"
        style={{ maxHeight: "calc(100vh - 499px" }}
      >
        {/* <CardPoolTracking
          empsByDesignation={empsByDesignation as TeamMember[]}
        /> */}
        {empsByDesignation?.map((listing: TeamMember, index) => (
          <EmployeeCard key={index} teamMember={listing} />
        ))}
      </div>
    </>
  );
};

export default TrackingFilter;
