import { Avatar, Chip } from "@heathmont/moon-core-tw";
import React from "react";
import { TeamMember } from "../../../../../../types/interfaces/IDayInfo";
import {
  getBorderColor,
  getTextColor,
} from "../../../../../../utils/Colors/colorSetting";
interface Props {
  teamMember: TeamMember;
}

const EmployeeCard: React.FC<Props> = ({ teamMember }) => {
  return (
    <>
      <div
        className={`bg-white relative rounded-md after:absolute after:rounded-md after:content-[''] after:top-[1px] after:bottom-[1px] after:right-0 after:-left-[4px]`}
      >
        <div
          className={`flex items-start flex-wrap  rounded-md p-[10px] mb-[10px] relative z-10 border border-beerus`}
        >
          <span className="inline-flex rounded-full shadow-md">
            <Avatar
              size="md"
              className={`rounded-full border-2  ${getBorderColor(
                teamMember?.role
              )} overflow-hidden`}
              imageUrl={""}
            />
          </span>
          <div className="flex-1 pl-[10px]">
            <div className="flex gap-3">
              <div className="flex-1">
                <Chip
                  size="sm"
                  className={`text-moon-9 ${getTextColor(
                    teamMember?.role
                  )} rounded-sm px-[8px] py-0 h-auto uppercase font-semibold`}
                >
                  {teamMember?.role}
                </Chip>
                <h3 className="font-bold text-moon-16 mb-[10px] line-clamp-1">
                  {teamMember?.employeeName}
                </h3>
              </div>
            </div>
            <div className="flex flex-wrap mb-[13px]">
              <div className="w-1/2">
                <div className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                  Hours{" "}
                </div>
                <div className="text-moon-12">
                  <strong className="text-moon-16 font-semibold">
                    {teamMember?.hoursSpent}
                  </strong>{" "}
                  <span className="text-moon-12">
                    {" "}
                    / {teamMember?.hoursAllocated}
                  </span>
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                  Shifts
                </div>
                <div>
                  <strong className="text-moon-16 font-semibold">
                    {teamMember?.shiftsSpent}
                  </strong>{" "}
                  <span className="text-moon-12">
                    {" "}
                    / {teamMember?.shiftsAllocated}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default EmployeeCard;
