import { Avatar } from "@heathmont/moon-core-tw";
import { Other3Dots } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import React from "react";
import { ISwapRequestResponse } from "..";
import moment from "moment";

const CardShiftList = ({
  createdAt,
  day,
  month,
  year,
  shift,
}: ISwapRequestResponse) => {
  const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  };
  return (
    <div className="flex flex-wrap items-center bg-white rounded-[8px] overflow-hidden mb-[4px] 2xl:text-base text-moon-14">
      <div className="2xl:flex-[0_0_246px] flex-[0_0_200px]  p-[17px_16px]">
        <p>{moment(new Date(createdAt)).format("DD MMMM yyyy")}</p>
      </div>
      <div className="2xl:flex-[0_0_246px] flex-[0_0_200px]  p-[17px_16px]">
        <p>{moment(new Date(year, month - 1, day)).format("DD MMMM yyyy")}</p>
      </div>
      <div className="2xl:flex-[0_0_246px] flex-[0_0_200px]  p-[17px_16px]">
        <div
          className={`inline-flex items-center gap-[5px] font-semibold  2xl:text-moon-16 text-${
            shift === "day" ? "krillin" : "#272D36"
          }`}
        >
          {shift === "day" ? (
            <span className=" 2xl:text-moon-18">
              <Icon icon="uil:sun" />
            </span>
          ) : (
            <span className=" 2xl:text-moon-18">
              <Icon icon="uil:moon" />
            </span>
          )}
          {capitalizeFirstLetter(shift)}
        </div>
      </div>
    </div>
  );
};

export default CardShiftList;
