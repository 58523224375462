import { Avatar } from "@heathmont/moon-core-tw";
import React from "react";
import moment from "moment";
import { IUserData } from "../../../types/interfaces/IUserData.interface";
const CardProfile = () => {
  const profileData: IUserData = JSON.parse(sessionStorage.getItem("user")!);
  function capitalizeWords(inputString: string) {
    let words = inputString.split(" ");
    let capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    let resultString = capitalizedWords.join(" ");

    return resultString;
  }
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex items-center gap-[15px] mb-[30px]">
        <span className="inline-flex rounded-full shadow-md ">
          <Avatar
            size="xl"
            className="rounded-full border-2 overflow-hidden"
            imageUrl="./images/Avatar.png"
          ></Avatar>
        </span>
        <div className="flex-1">
          <h3 className="text-moon-20 font-semibold text-blackcst  leading-[28px] capitalize">
            {profileData.firstName + " " + profileData.lastName}
          </h3>
          <p className="text-moon-14 font-semibold text-primary leading-[20px] capitalize">
            {profileData?.role}
          </p>
        </div>
      </div>
      <div className="">
        <div className="flex flex-wrap -mx-3 items-start my-4">
          <div className="w-1/4 px-3">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Email:
            </h4>
          </div>
          <div className="w-3/4 px-3">
            <h4 className="text-moon-14">{profileData?.email}</h4>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 items-start my-4">
          <div className="w-1/4 px-3">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Phone:
            </h4>
          </div>
          <div className="w-3/4 px-3">
            <h4 className="text-moon-14">{profileData?.contactNo}</h4>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 items-start my-4">
          <div className="w-1/4 px-3">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Identity:
            </h4>
          </div>
          <div className="w-3/4 px-3">
            <h4 className="text-moon-14">{profileData?.employeeId}</h4>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 items-start my-4">
          <div className="w-1/4 px-3">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Joined:
            </h4>
          </div>
          <div className="w-3/4 px-3">
            <h4 className="text-moon-14">
              {moment(profileData?.joiningDate)?.format("DD MMM YYYY")}
            </h4>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 items-start my-4">
          <div className="w-1/4 px-3">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Address:
            </h4>
          </div>
          <div className="w-3/4 px-3">
            <h4 className="text-moon-14">
              {/* Suzy Queue. 4455 Landing Lange, APT 4. Louisville, KY 40018-1234. */}
              {profileData?.address}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProfile;
